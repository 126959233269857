<template>
  <div id="notification">
    <van-nav-bar
      :title="$t('app.notifications')"
      left-arrow
      @click-left="() => $router.back()"
      @click-right="allMarkReaded"
      :right-text="$t('notification.all_mark_readed')"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="fetchNotifications"
        :finished-text="$t('common.not_more')"
      >
        <van-swipe-cell
          v-for="item in notifications"
          :key="item.id"
        >
          <van-cell
            clickable
            is-link
            @click="readNotification(item)"
          >
            <span :class="{ 'text-danger': !item.readed }">{{ item.title }}</span>
          </van-cell>
          <template #right>
            <van-button square type="danger" @click="markUnreaded(item)" :text="$t('notification.mark_unreaded')" />
          </template>
        </van-swipe-cell>
      </van-list>
    </van-pull-refresh>
    <van-popup
      v-model="showPopup"
      position="right"
      get-container="#app"
      :style="{ width: '100%', height: '100%' }"
      v-if="notification"
    >
      <van-nav-bar
        left-arrow
        @click-left="showPopup = false"
      />
      <div id="notification-render">
        <h3 class="title">{{ notification.title }}</h3>
        <section class="time">{{ notification.created_at }}</section>
        <section class="content" v-html="notification.content"></section>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  NavBar, PullRefresh, List, SwipeCell,
} from 'vant';

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(SwipeCell);

export default {
  name: 'Notification',
  data() {
    return {
      notifications: [],
      pagination: null,
      notification: null,
      page: 1,
      loading: false,
      finished: false,
      refreshing: false,
      showPopup: false,
    };
  },
  computed: {
    ...mapState({
      notificationsReaded: state => state.app.notificationsReaded,
    }),
  },
  mounted() {

  },
  methods: {
    onRefresh() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.fetchNotifications();
    },
    fetchNotifications() {
      if (this.refreshing) {
        this.notifications = [];
        this.refreshing = false;
      }
      this.$api.app.notifications({ page: this.page }).then(({ data: response }) => {
        this.pagination = response.meta.pagination;
        if (this.page < this.pagination.total_pages) {
          this.page++;
        } else {
          this.finished = true;
        }
        response.data.forEach((item) => {
          /* eslint-disable no-param-reassign */
          item.readed = ~this.notificationsReaded.indexOf(item.id);
          this.notifications.push(item);
        });
      }).catch(() => {
        this.finished = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    readNotification(notification) {
      notification.readed = true;
      this.$store.commit('readNotification', notification.id);
      this.notification = notification;
      this.showPopup = true;
    },
    allMarkReaded() {
      this.notifications.forEach((notification) => {
        notification.readed = true;
        this.$store.commit('readNotification', notification.id);
      });
    },
    markUnreaded(notification) {
      notification.readed = false;
      this.$store.commit('unreadNotification', notification.id);
    },
  },
};
</script>

<style lang="scss">
  @import "../style/variables";
  #notification-render {
    padding: 0 1rem;
    .title {
      text-align: center;
    }
    .time {
      text-align: center;
      font-size: .5rem;
      color: $gray-7;
    }
    .content {
      img {
        width: 100%;
      }
    }
  }
</style>
